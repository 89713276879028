import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
`
const ProjLink = styled(Link)`
  padding: 4px;
  font-weight: 300;
  font-size: 15px;
  text-align: center;
  font-variant: small-caps;
  color: #efefef;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    color: black;
    font-size: 16px;
    font-variant: small-caps;
  }
`

const ProjLinks = () => (
  <Flex>
    <ProjLink to="/gameswithhue/" activeClassName="current-page">
      Games with Hue
    </ProjLink>
    <ProjLink to="/stasisrehab/" activeClassName="current-page">
      Stasis Rehab
    </ProjLink>
    <ProjLink to="/iconoclasm/" activeClassName="current-page">
      Iconoclasm
    </ProjLink>
    <ProjLink to="/vtts/" activeClassName="current-page">
      VTTS Finance
    </ProjLink>
  </Flex>
)
export default ProjLinks
