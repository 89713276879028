import React from "react"
import { Global, css } from "@emotion/core"
import "@fontsource/lato"

const Layout = ({ children }) => (
  <>
    <Global
      styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
        }

        html,
        body {
          margin: 0;
          font-family: "Lato";
          scroll-behavior: smooth;
          > div {
            margin-top: 0;
          }
        }
      `}
    />

    <main
      css={css`
        margin: 0;
        max-width: 100vw;
        background: linear-gradient(180deg, #1f264d 0%, #3d4c99 94.36%);
      `}
    >
      {children}
    </main>
  </>
)

export default Layout
